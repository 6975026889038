require('./lib/materialize.min.js');
require('./bootstrap');
require('bootstrap-select/dist/js/bootstrap-select.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
require('./lib/slick.min.js');
require('./lib/jquery.matchHeight-min.js');
require('./lib/jquery.magnifier.min.js');
require('./lib/jquery.scrollbar.min.js');
require('./lib/jquery.scrollbar.min.js');
require('./lib/jquery.autocomplete.js');

require('nouislider/dist/nouislider.min.mjs');
require('isotope-layout/dist/isotope.pkgd.min.js');
require('intl-tel-input/build/js/intlTelInput-jquery.min.js');
require('intl-tel-input/build/js/utils.js');
require('jquery-confirm/dist/jquery-confirm.min.js');

$('.selectpicker').selectpicker();

$('#toggle_menu_mobile').click(function () {
    $('body').toggleClass('slide-menu-active');
    $(this).toggleClass('active');
    $('.slide-menu-area').toggleClass('active');
});

$('.toggle-menu').click(function () {
    $('body').toggleClass('slide-menu-active');
    $(this).toggleClass('active');
    $('.slide-menu-area').toggleClass('active');
});

$('.slide-menu-close-area').click(function () {
    $('body').removeClass('slide-menu-active');
    $('.toggle-menu').removeClass('active');
    $('.slide-menu-area').removeClass('active');
});

$('#close_slide_menu').click(function () {
    $('body').removeClass('slide-menu-active');
    $('.slide-menu-area').removeClass('active');
});

$('.custom-scrollbar').scrollbar({
    ignoreMobile:true,
});

var headerHeight = $('#header').height();
$('.shopping-cart-section .shopping-cart').css('top', headerHeight + 'px');

$('#alert_top').on('closed.bs.alert', function () {
    var headerHeight = $('.header-elements').height();
    $('.shopping-cart-section .shopping-cart').css('top', headerHeight + 'px');
});

$('.shopping-cart-item a').click(function () {
    $(this).toggleClass('active');
    $('.shopping-cart-area').toggleClass('active');
    // $('.shopping-cart').removeClass('scrolled');
    $('.shopping-cart').toggleClass('active');
});

$('.close-shopping-cart').click(function () {
    $('.shopping-cart').removeClass('active');
});

$('.search-item').click(function () {
    $(this).toggleClass('active');
    $('.search-area').toggleClass('active');
    $('.sell-trade-in a').toggleClass('d-none');
    // $('#header').toggleClass('search-active');
    $('.main-menu').toggleClass('active');
});

var accordion_menu_head = $('.menu-accordion ul > li.parent > a'),
    accordion_menu_body = $('.menu-accordion ul > li.parent > .sub-menu');

accordion_menu_head.on('click', function(event) {
    event.preventDefault();
    if ($(this).attr('class') != 'parent-active'){
        accordion_menu_body.slideUp('normal');
        $(this).next().stop(true,true).slideToggle('normal');
        accordion_menu_head.removeClass('parent-active');
        $(this).addClass('parent-active');
    }
});

var headerHeight = $('#header').height();
var advertisingBottomHeight = $('#advertising_bottom').height();
var totalHeight = advertisingBottomHeight ? headerHeight + advertisingBottomHeight : headerHeight;
if ($(window).width() > 991) {
    let getHeight = ($(window).height() - totalHeight);
    // let translate = $(window).height()/totalHeight;
    // alert(translate*10);
    // alert((translate*10) - (totalHeight/100));
    $('.landing').css('height', getHeight+'px');
    // let landing_css = 'transform:translate('+(59 - (totalHeight*.100))+'%) skew(-'+(40 + (totalHeight*.100))+'deg) scaleX(1)';
    let landing_css = 'transform: translate(40%) skew(-62.5deg) scaleX(1)';
    let landing_width = 'height:'+getHeight+'px';
    $('head').append('<style>.slideshow.landing:before{'+landing_css+'}<style>');
    $('head').append('<style>.slideshow.landing:before{'+landing_width+'}<style>');
}

const alert_top = document.getElementById('alert_top');
const alert_bottom = document.getElementById('alert_bottom');
if (alert_top) {
    $('#alert_top').on('closed.bs.alert', function () {
        $('#advertising_top').empty();
        var headerHeight = $('#header').height();
        var advertisingBottomHeight = $('#advertising_bottom').height();
        var totalHeight = advertisingBottomHeight ? headerHeight + advertisingBottomHeight : headerHeight;

        if ($(window).width() > 991) {
             $('#slideshow').addClass('alert_top_remove');
            let getHeight = ($(window).height() - totalHeight);
            $('.landing').css('height', getHeight+'px');
            // let landing_css = 'transform:translate('+(59 - ((totalHeight*.100) + 10))+'%) skew(-'+(40 + (totalHeight*.100))+'deg) scaleX(1)';
            let landing_css = 'transform: translate(40%) skew(-59deg) scaleX(1)';
            if($('#advertising_bottom').height() > 0){
                landing_css = 'transform: translate(40%) skew(-60.5deg) scaleX(1)';
            }
            let landing_width = 'height:'+getHeight+'px';
            $('head').append('<style>.alert_top_remove.slideshow.landing:before{'+landing_css+'}<style>');
            $('head').append('<style>.slideshow.landing:before{'+landing_width+'}<style>');
        }
    });
}

if (alert_bottom) {
     $('#alert_bottom').on('closed.bs.alert', function () {
        $('#advertising_bottom').empty();
        var headerHeight = $('#header').height();
        var advertisingBottomHeight = $('#advertising_bottom').height();
        var totalHeight =advertisingBottomHeight ? headerHeight + advertisingBottomHeight : headerHeight;
        if ($(window).width() > 991) {
            $('#slideshow').addClass('alert_bottom_remove');
            let getHeight = ($(window).height() - totalHeight);
            $('.landing').css('height', getHeight+'px');
            // let landing_css = 'transform:translate('+(59 - ((totalHeight*.100) + 10))+'%) skew(-'+(40 + (totalHeight*.100))+'deg) scaleX(1)';
            let landing_css = 'transform: translate(40%) skew(-60.5deg) scaleX(1)';
            if($('#advertising_top').height() == 0){
                landing_css = 'transform: translate(40%) skew(-59deg) scaleX(1)';
            }
            let landing_width = 'height:'+getHeight+'px';
            $('head').append('<style>.alert_bottom_remove.slideshow.landing:before{'+landing_css+'}<style>');
            $('head').append('<style>.slideshow.landing:before{'+landing_width+'}<style>');
        }
    });
}

$(function () {
    $(window).on('scroll', function() {
        var offset = $(window).scrollTop();

        var header_height = $('.header-elements').height();
        if(offset >= header_height){
            $('.animate-header').addClass('active');
            var header_height = $('.animate-header').height();
            $('.shopping-cart-section .shopping-cart').css('top', header_height + 'px');
        }
        if(offset <= header_height){
            header_height = $('#header').height();
            $('.animate-header').removeClass('active');
            $('.shopping-cart-section .shopping-cart').css('top', header_height + 'px');
        }
    });
});

$(function () {
    $(window).on('scroll', function() {
        var offset = $(window).scrollTop();

        var showcase_height = $('#showcase').height();
        if(offset >= showcase_height){
            $('.buy-now-action-bar').addClass('active');
        }
        if(offset <= showcase_height){
            $('.buy-now-action-bar').removeClass('active');
        }
    });
});

var $backToTop = $('.back_to_top');
$backToTop.hide();

$(window).on('scroll', function() {
  if ($(this).scrollTop() > 100) {
    $backToTop.fadeIn();
  } else {
    $backToTop.fadeOut();
  }
});

$backToTop.on('click', function(e) {
  $("html, body").animate({scrollTop: 0}, 500);
});

$("#buy_now_close_button").click(function () {
    $("#buy_now_close_section").toggleClass("active");
});

$('#tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#guest_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipinfo.io/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#shipping_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#payment_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#contact_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: false,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$("form").submit(function () {
    var dialCode = $("#tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#tel_cell_country_code").val(dialCode);

    var guestDialCode = $("#guest_tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#guest_tel_cell_country_code").val(guestDialCode);

    var shippingDialCode = $("#shipping_tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#shipping_tel_cell_country_code").val(shippingDialCode);

    var paymentDialCode = $("#shipping_tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#payment_tel_cell_country_code").val(paymentDialCode);

    var contactDialCode = $("#tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#contact_tel_cell_country_code").val(contactDialCode);

    // $("#guest_tel_cell_country_code").val(dialCode);
});

// let getTelCell = document.querySelector("#tel_cell");
// let errorMsg = document.querySelector("#error-msg");
// let validMsg = document.querySelector("#valid-msg");
// let errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

// let reset = function () {
//     validMsg.innerHTML = "The number seems good";
//     errorMsg.classList.add("d-none");
//     validMsg.classList.add("d-none");
// };

// if (getTelCell) {
//     // on blur: validate
//     getTelCell.addEventListener('blur', function () {
//         reset();
//         if (getTelCell.value.trim()) {
//             var num = $("#tel_cell").intlTelInput("isValidNumber");
//             if (num) {
//                 validMsg.classList.remove("d-none");
//             } else {
//                 var errorCode = $("#tel_cell").intlTelInput("getValidationError");
//                 errorMsg.innerHTML = errorMap[errorCode];
//                 errorMsg.classList.remove("d-none");
//             }
//         }
//     });

//     // on keyup / change flag: reset
//     getTelCell.addEventListener('change', reset);
//     getTelCell.addEventListener('keyup', reset);
// }

// $('.stars li').on('mouseover', function () {
//     var onStar = parseInt($(this).data('value'), 10);
//     $(this).parent().children('li.star').each(function (e) {
//         if (e < onStar) {
//             $(this).addClass('hover');
//         } else {
//             $(this).removeClass('hover');
//         }
//     });
// }).on('mouseout', function () {
//     $(this).parent().children('li.star').each(function (e) {
//         $(this).removeClass('hover');
//     });
// });

// $('.stars li').on('click', function () {
//     var onStar = parseInt($(this).data('value'), 10); // The star currently selected
//     var stars = $(this).parent().children('li.star');

//     for (i = 0; i < stars.length; i++) {
//         $(stars[i]).removeClass('selected');
//     }

//     for (i = 0; i < onStar; i++) {
//         $(stars[i]).addClass('selected');
//     }

//     // JUST RESPONSE (Not needed)
//     var ratingValue = parseInt($('.stars li.selected').last().data('value'), 10);
//     var msg = "";
//     if (ratingValue > 1) {
//         msg = "Thanks! You rated this " + ratingValue + " stars.";
//     } else {
//         msg = "We will improve ourselves. You rated this " + ratingValue + " stars.";
//     }
//     responseMessage(msg);
//     $('.review_rating').val(ratingValue);
// });
