require('./lib/materialize.min.js');
require('./bootstrap');
require('bootstrap-select/dist/js/bootstrap-select.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
require('./lib/slick.min.js');
require('./lib/jquery.matchHeight-min.js');
require('./lib/jquery.magnifier.min.js');
require('./lib/jquery.scrollbar.min.js');
require('./lib/jquery.scrollbar.min.js');
require('./lib/jquery.autocomplete.js');

require('nouislider/dist/nouislider.min.mjs');
require('isotope-layout/dist/isotope.pkgd.min.js');
require('intl-tel-input/build/js/intlTelInput-jquery.min.js');
require('intl-tel-input/build/js/utils.js');
require('jquery-confirm/dist/jquery-confirm.min.js');

$('.selectpicker').selectpicker();

$('#toggle_menu_mobile').click(function () {
    $('body').toggleClass('slide-menu-active');
    $(this).toggleClass('active');
    $('#mobile_menu_area').toggleClass('active');
    // $('.slide-menu-area').toggleClass('active');
});

$('.toggle-menu').click(function () {
    $('body').toggleClass('slide-menu-active');
    $(this).toggleClass('active');
    $('#slide_menu_area').toggleClass('active');
    // $('.slide-menu-area').toggleClass('active');
});

$('.slide-menu-close-area').click(function () {
    $('body').removeClass('slide-menu-active');
    $('.toggle-menu').removeClass('active');
    $('.slide-menu-area').removeClass('active');
});
$('.mobile-menu-close-area').click(function () {
    $('body').removeClass('slide-menu-active');
    $('.toggle-menu').removeClass('active');
    $('#mobile_menu_area').removeClass('active');
});

$('#close_slide_menu').click(function () {
    $('body').removeClass('slide-menu-active');
    $('.slide-menu-area').removeClass('active');
});
$('#close_slide_mobile_menu').click(function () {
    $('body').removeClass('slide-menu-active');
    $('#mobile_menu_area').removeClass('active');
});

$('.custom-scrollbar').scrollbar({
    ignoreMobile:true,
});

var headerHeight = $('#header').height();
$('.shopping-cart-section .shopping-cart').css('top', headerHeight + 'px');

$('#alert_top').on('closed.bs.alert', function () {
    var headerHeight = $('.header-elements').height();
    $('.shopping-cart-section .shopping-cart').css('top', headerHeight + 'px');
});

$('.shopping-cart-item a').click(function () {
    $(this).toggleClass('active');
    $('.shopping-cart-area').toggleClass('active');
    // $('.shopping-cart').removeClass('scrolled');
    $('.shopping-cart').toggleClass('active');
});

$('.close-shopping-cart').click(function () {
    $('.shopping-cart').removeClass('active');
});

$('.search-item').click(function () {
    $(this).toggleClass('active');
    $('.search-area').toggleClass('active');
    $('.sell-trade-in a').toggleClass('d-none');
    // $('#header').toggleClass('search-active');
    $('.main-menu').toggleClass('active');
});

$('.accordion-btn').on('click', function() {
    $(this).closest('.card').toggleClass('active');
});

var headerHeight = $('#header').height();
var advertisingBottomHeight = $('#advertising_bottom').height();
var totalHeight = advertisingBottomHeight ? headerHeight + advertisingBottomHeight : headerHeight;
if ($(window).width() > 991) {
    let getHeight = ($(window).height() - totalHeight);
    // let translate = $(window).height()/totalHeight;
    // alert(translate*10);
    // alert((translate*10) - (totalHeight/100));
    $('.landing').css('height', getHeight+'px');
    // let landing_css = 'transform:translate('+(59 - (totalHeight*.100))+'%) skew(-'+(40 + (totalHeight*.100))+'deg) scaleX(1)';
    let landing_css = 'transform: translate(40%) skew(-62.5deg) scaleX(1)';
    let landing_width = 'height:'+getHeight+'px';
    $('head').append('<style>.slideshow.landing:before{'+landing_css+'}<style>');
    $('head').append('<style>.slideshow.landing:before{'+landing_width+'}<style>');
}

const alert_top = document.getElementById('alert_top');
const alert_bottom = document.getElementById('alert_bottom');
if (alert_top) {
    $('#alert_top').on('closed.bs.alert', function () {
        $('#advertising_top').empty();
        var headerHeight = $('#header').height();
        var advertisingBottomHeight = $('#advertising_bottom').height();
        var totalHeight = advertisingBottomHeight ? headerHeight + advertisingBottomHeight : headerHeight;

        if ($(window).width() > 991) {
             $('#slideshow').addClass('alert_top_remove');
            let getHeight = ($(window).height() - totalHeight);
            $('.landing').css('height', getHeight+'px');
            // let landing_css = 'transform:translate('+(59 - ((totalHeight*.100) + 10))+'%) skew(-'+(40 + (totalHeight*.100))+'deg) scaleX(1)';
            let landing_css = 'transform: translate(40%) skew(-59deg) scaleX(1)';
            if($('#advertising_bottom').height() > 0){
                landing_css = 'transform: translate(40%) skew(-60.5deg) scaleX(1)';
            }
            let landing_width = 'height:'+getHeight+'px';
            $('head').append('<style>.alert_top_remove.slideshow.landing:before{'+landing_css+'}<style>');
            $('head').append('<style>.slideshow.landing:before{'+landing_width+'}<style>');
        }
    });
}

if (alert_bottom) {
     $('#alert_bottom').on('closed.bs.alert', function () {
        $('#advertising_bottom').empty();
        var headerHeight = $('#header').height();
        var advertisingBottomHeight = $('#advertising_bottom').height();
        var totalHeight =advertisingBottomHeight ? headerHeight + advertisingBottomHeight : headerHeight;
        if ($(window).width() > 991) {
            $('#slideshow').addClass('alert_bottom_remove');
            let getHeight = ($(window).height() - totalHeight);
            $('.landing').css('height', getHeight+'px');
            // let landing_css = 'transform:translate('+(59 - ((totalHeight*.100) + 10))+'%) skew(-'+(40 + (totalHeight*.100))+'deg) scaleX(1)';
            let landing_css = 'transform: translate(40%) skew(-60.5deg) scaleX(1)';
            if($('#advertising_top').height() == 0){
                landing_css = 'transform: translate(40%) skew(-59deg) scaleX(1)';
            }
            let landing_width = 'height:'+getHeight+'px';
            $('head').append('<style>.alert_bottom_remove.slideshow.landing:before{'+landing_css+'}<style>');
            $('head').append('<style>.slideshow.landing:before{'+landing_width+'}<style>');
        }
    });
}

$(function () {
    $(window).on('scroll', function() {
        var offset = $(window).scrollTop();

        var header_height = $('.header-elements').height();
        if(offset >= header_height){
            $('.animate-header').addClass('active');
            var header_height = $('.animate-header').height();
            $('.shopping-cart-section .shopping-cart').css('top', header_height + 'px');
        }
        if(offset <= header_height){
            header_height = $('#header').height();
            $('.animate-header').removeClass('active');
            $('.shopping-cart-section .shopping-cart').css('top', header_height + 'px');
        }
    });
});

$(function () {
    $(window).on('scroll', function() {
        var offset = $(window).scrollTop();

        var showcase_height = $('#showcase').height();
        if(offset >= showcase_height){
            $('.buy-now-action-bar').addClass('active');
        }
        if(offset <= showcase_height){
            $('.buy-now-action-bar').removeClass('active');
        }
    });
});

var $backToTop = $('.back_to_top');
$backToTop.hide();

$(window).on('scroll', function() {
  if ($(this).scrollTop() > 100) {
    $backToTop.fadeIn();
  } else {
    $backToTop.fadeOut();
  }
});

$backToTop.on('click', function(e) {
  $("html, body").animate({scrollTop: 0}, 500);
});

$("#buy_now_close_button").click(function () {
    $("#buy_now_close_section").toggleClass("active");
});

$('#tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#guest_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipinfo.io/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#shipping_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#payment_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: true,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$('#contact_tel_cell').intlTelInput({
    initialCountry: "auto",
    separateDialCode: true,
    formatOnDisplay: false,
    geoIpLookup: function(callback) {
        fetch("https://ipapi.co/json")
        .then(function(res) { return res.json(); })
        .then(function(data) { callback(data.country_code); })
        .catch(function() { callback("us"); });
    }
});

$("form").submit(function () {
    var dialCode = $("#tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#tel_cell_country_code").val(dialCode);

    var guestDialCode = $("#guest_tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#guest_tel_cell_country_code").val(guestDialCode);

    var shippingDialCode = $("#shipping_tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#shipping_tel_cell_country_code").val(shippingDialCode);

    var paymentDialCode = $("#shipping_tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#payment_tel_cell_country_code").val(paymentDialCode);

    var contactDialCode = $("#tel_cell").intlTelInput("getSelectedCountryData").dialCode;
    $("#contact_tel_cell_country_code").val(contactDialCode);

    // $("#guest_tel_cell_country_code").val(dialCode);
});

const pages = document.querySelectorAll(".page");
const translateAmount = 100;
let translate = 0;

slide = (direction) => {
    direction === "next" ? translate -= translateAmount : translate += translateAmount;
    pages.forEach(
        pages => (pages.style.transform = `translateX(${translate}%)`)
    );
}

$(document).on('click', '.have-sub-menu', function () {
    var menu_id = $(this).data('menu_id');
    url = get_sub_menu_route + '?menu_id=' + menu_id;

    $.get(url, function (response) {
        $('#child_mobile_menus').empty().append(response);
    });

    url = get_sub_menu_route + '?menu_id=' + menu_id + '&menu_title=1';
    $.get(url, function (response) {
        $("#child_parent_title span").empty().html(response.menu_name);
        $("#child_parent_title").attr('data-menu_id', response.parent_menu_id);
        $('.mobile-col').removeClass('main-mobile-menu').addClass('child-mobile-menu-area');
        $('.menu-accordion-mobile.sub-page-menu').removeClass('d-none');
        $('.menu-accordion-mobile').addClass('secondary-area');
        $('.child-mobile-menu-area').addClass('active');
        $('.bottom-block').addClass('d-none');
        $('.main-mobile-menu').addClass('d-none');
        if(response.parent_menu_id){
            $("#child_parent_active").val(1);
        }else{
            $("#child_parent_active").val(0);
            slide('next');
        }
    });
});

$(document).on('click', '#child_parent_title', function () {
    let menu_id = $(this).data('menu_id');
    var mobile_parent_menu = $("#child_parent_active").val();

    if(mobile_parent_menu == 1){
        url = get_sub_menu_route + '?menu_id=' + menu_id;
        $.get(url, function (response) {
            $('#child_mobile_menus').empty().append(response);
        });

        url = get_sub_menu_route + '?menu_id=' + menu_id + '&menu_title=1';
        $.get(url, function (response) {
            $("#child_parent_title span").empty().html(response.menu_name);
            $("#child_parent_title").attr('data-menu_id', response.parent_menu_id);
            // slide('next');
            if(response.parent_menu_id){
               $("#child_parent_active").val(1);
            }else{
                $("#child_parent_active").val(0);
            }
        });
    }
    if(mobile_parent_menu == 0){
        slide();
        $('.mobile-col').addClass('main-mobile-menu').removeClass('child-mobile-menu-area');
        $('.menu-accordion-mobile').removeClass('secondary-area');
        $('.menu-accordion-mobile.sub-page-menu').addClass('d-none');
        $('.child-mobile-menu-area').removeClass('active');
        $('.bottom-block').removeClass('d-none');
        $('.main-mobile-menu').removeClass('d-none')
    }
});
